var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index container"},[_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{on:{"showParent":_vm.showParent}})],1),(_vm.showParentPage)?_c('div',[_c('v-breadcrumbs',[_c('v-breadcrumbs-item',{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c('v-breadcrumbs-divider',[_vm._v("/")]),_c('v-breadcrumbs-item',{attrs:{"exact":true,"to":{ name: 'pageParameters', params: {} },"active":""}},[_vm._v(" Page Parameters ")])],1),_c('div',{staticClass:"d-flex flex-column flex-lg-row"},[_c('v-text-field',{attrs:{"dense":"","placeholder":"Press enter to begin search","append-icon":"mdi-magnify","outlined":"","clearable":"","label":"Search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"click:clear":_vm.search,"click:append":_vm.search},model:{value:(_vm.searchTerms),callback:function ($$v) {_vm.searchTerms=$$v},expression:"searchTerms"}}),_c('v-btn',{staticClass:"ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start",attrs:{"color":"primary","to":{ name: 'pageParametersCreate', params: {} }}},[_vm._v(" Create Page Parameters ")])],1),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pageParametersList,"items-per-page":_vm.pagination.pageSize,"loading":!_vm.pageParametersProcessed,"server-items-length":_vm.pagination.totalSize,"page":_vm.pagination.current,"disable-sort":true,"item-key":"id"},on:{"update:options":_vm.pageChanged},scopedSlots:_vm._u([{key:"item.owner_only",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.view(item)}}},[_vm._v(_vm._s(item.owner_only))])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.view(item)}}},[_vm._v(_vm._s(item.created_by))])]}},{key:"item.create_time",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.view(item)}}},[_vm._v(_vm._s(item.create_time))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,2389351235)})],1)],1):_vm._e(),_c('confirmation-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }